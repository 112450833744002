<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <div>
        <apexchart
          :options="chartOptions"
          :series="[overallHealthScore]"
          :height="height"
          type="radialBar"
        />
      </div>
      <div
        class="flex-grow"
        style="min-width: 310px;"
      >
        <table class="table table-sm healthTable">
          <thead>
            <tr>
              <td />
              <td>Score</td>
              <td>Issues</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="healthCat in asup.details.metrics.systemHealthMetric.healthCategories"
              :key="healthCat.name"
            >
              <td>
                <feather-icon
                  :icon="getIconByCategory(healthCat.name)"
                  class="mr-1"
                />
                {{ healthCat.name }}
              </td>
              <td>
                <feather-icon
                  :icon="healthCat.status === 'GOOD' ? 'CheckCircleIcon' : healthCat.status === 'FAIR' ? 'AlertTriangleIcon' :'AlertCircleIcon'"
                  :class="healthCat.status === 'GOOD' ? 'text-success' : healthCat.status === 'FAIR' ? 'text-warning' : 'text-danger'"
                />
              </td>
              <td>{{ healthCat.issuesCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-link
      :to="{name: 'ppdm-detail.health-issues'}"
      class="float-right mt-2"
    >View all</b-link>
  </div>
</template>

<script>

import { BLink } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BLink,
    apexchart: VueApexCharts,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    chartOptions() {
      const that = this
      return {
        chart: {
          height: that.height,
          width: that.height,
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              value: {
                offsetY: 5,
                formatter(val) {
                  return val
                },
              },
            },
          },
        },
        labels: [that.overallHealthStatus],
        colors: [function () {
          if (that.overallHealthStatus === 'GOOD') {
            return $themeColors.success
          }
          if (that.overallHealthStatus === 'FAIR') {
            return $themeColors.warning
          }

          return $themeColors.danger
        }],
      }
    },
    overallHealthScore() {
      return this.asup.details.metrics.systemHealthMetric.score ?? 0
    },
    overallHealthStatus() {
      return this.asup.details.metrics.systemHealthMetric.status ?? 0
    },
  },
  methods: {
    getIconByCategory(categoryName) {
      if (!categoryName) { return '' }

      switch (categoryName) {
        case 'CONFIGURATION': return 'SettingsIcon'
        case 'CAPACITY': return 'DatabaseIcon'
        case 'PERFORMANCE': return 'BarChartIcon'
        case 'COMPONENTS': return 'FileTextIcon'
        case 'DATA_PROTECTION': return 'ShieldIcon'
        default: return ''
      }
    },
  },
}
</script>

<style scoped>
.healthTable tr td {
    border-top: none;
    border-bottom: none;
}
.healthTable tr td:not(:last-child) {
  border-right: 1px solid rgb(235, 233, 241);
}
.healthTable thead tr {
  border-bottom: 1px solid rgb(235, 233, 241);
}
</style>
