<template>
  <div>
    <apexchart
      type="pie"
      :options="chartOptions"
      :series="[compliantCount, notCompliantCount]"
      :height="height"
    />
  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    compliantCount: {
      type: Number,
      default: 0,
    },
    notCompliantCount: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 180,
    },
  },
  computed: {
    chartOptions() {
      const that = this
      return {
        chart: {
          height: that.height,
        },
        labels: ['Compliant', 'Not Compliant'],
        title: {
          text: that.title,
          align: 'center',
        },
        legend: {
          position: 'bottom',
        },
        colors: [function ({ value, seriesIndex }) {
          if (seriesIndex === 1) {
            if (value === 0) {
              return '#696969' // gray for 0
            }
            return $themeColors.danger // red if not compliant
          }
          return $themeColors.success // green for compliant
        }],
        dataLabels: {
          // show values instead of percentage
          formatter(value, { seriesIndex }) {
            if (seriesIndex === 0) {
              return that.compliantCount
            }

            return that.notCompliantCount
          },
        },
      }
    },
  },
}
</script>
